import {createRoot} from "react-dom/client";
import JshModule from '@core/scripts/helper/jsh-module';
import {AppInfo} from "../../components/AppInfo";

type AppInstallConfig = {
    appStoreLink: string
    appTitle: string
    appInstall: string
}

const {moduleScope, config} = JshModule('header/app-info') as { moduleScope: any, config: AppInstallConfig };

createRoot(moduleScope).render(
    <AppInfo appTitle={config.appTitle} installLabel={config.appInstall} installUrl={config.appStoreLink}/>
);